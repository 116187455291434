import React from 'react'
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router"

import useSiteMetadata from '../components/SiteMetadata'

const DEFAULT_IMAGE_URL = '/img/subway.jpg'

export const withHelmet = (BaseComponent) => (props) => {
  const { title, description, url } = useSiteMetadata()
  const { pathname, origin } = useLocation()

  const {
    pagename: pageName,
    title: pageTitle,
    description: pageDescription,
  } = props.data.markdownRemark.frontmatter

  const titleToUse = pageName || [pageTitle, title].filter(Boolean).join(' | ')

  const descriptionToUse = pageDescription || description
  const imageUrl = `${url}${DEFAULT_IMAGE_URL}`

  return (
    <React.Fragment>
      <Helmet
        title={titleToUse}
        htmlAttributes={{ lang: "en" }}
        meta={[
          {
            name: `description`,
            content: descriptionToUse,
          },
          {
            property: "og:url",
            content: `${url}${pathname}`
          },
          {
            property: "og:type",
            content: "website"
          },
          {
            property: "og:title",
            content: titleToUse
          },
          {
            property: "og:description",
            content: descriptionToUse
          },
          {
            property: "og:image",
            content: imageUrl
          },
          {
            property: "fb:app_id",
            content: "101003152424259"
          },
          {
            property: "twitter:site",
            content: "hugeforassembly"
          },
          {
            property: "twitter:description",
            content: descriptionToUse
          },
          {
            property: "twitter:title",
            content: titleToUse
          },
          {
            property: "twitter:image",
            content: imageUrl
          },
          {
            property: "twitter:image:alt",
            content: "Portrait of Huge Ma"
          },
        ]}
      />
      <BaseComponent {...props} />
    </React.Fragment>
  )
}
