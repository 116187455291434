import React from 'react'
import PropTypes from 'prop-types'
import { Box, Container, Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

export default function HeroBanner({ title, subtitle, image, children, styles, titleProps = {} }) {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const withImage = !!image;
  const align = (isSmallScreen || !withImage) ? 'center' : 'inherit';

  const outerPadding = withImage ?
    { py: { xs: 0, md: 14 }} :
    { py: { xs: 10,  md: 20 }};

  const maxHeight = isSmallScreen ? '500px' : 'inital';

  return (
    <Box
      sx={outerPadding}
      bgcolor="primary.main">
      <Container disableGutters={isSmallScreen}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-around"
          columnSpacing={6}
        >
          <Grid item xs="12" md={image ? 5 : 12 } order={{ xs: 1, md: 0 }}>
            <Box sx={{
              px: { xs: 4, md: 0 },
              py: { xs: 5, md: 0 },
            }}>
              { title && (
                <Typography
                  sx={{
                    color: 'white.main',
                    overflowWrap: 'break-word',
                  }}
                  gutterBottom={!!subtitle}
                  variant="h4"
                  component="h1"
                  align={align}
                  {...titleProps}
                  >
                  {title}
                </Typography>
              )}
              { subtitle && (
                <Typography
                  sx={{
                    color: 'white.main',
                  }}
                  gutterBottom={!!children}
                  align={align}
                >
                  {subtitle}
                </Typography>
              )}
              { children }
            </Box>
          </Grid>
          { image && (
            <Grid item xs="12" md="7" order={{ xs: 0, md: 1 }}>
              <Box>
                <PreviewCompatibleImage
                  imageStyle={{ maxHeight: maxHeight }}
                  imageInfo={{
                    image: image,
                    alt: 'some image',
                  }} />
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  )
}

HeroBanner.propTypes = PropTypes.shape({
  quote: PropTypes.string,
  author: PropTypes.string,
})
